import React from "react";
import { useNavigate } from "react-router-dom";

import { Theme } from "../../components/UI/Theme";
import { Box, Typography } from "@mui/material";
import ChirzModal from "../../components/UI/ChirzModal";
import { ChirzBtn } from "../../components/UI/ChirzButtons";

import welcome from "../../assets/images/welcome.png";

const WelcomeModal = (props) => {
  const navigate = useNavigate();
  const closeHandler = () => {
    props.onOpen(false);
    navigate("/");
  };

  const bgBanner = {
    objectPosition: "center",
    objectFit: "cover",
  };
  return (
    <ChirzModal open={props.open} innerWidth="auto">
      <Box sx={{ borderRadius: "16px", overflow: "hidden" }}>
        <Box width="100%" height={186}>
          <img src={welcome} width="100%" height="100%" style={bgBanner} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="8px"
          width="100%"
          minHeight={190}
          padding="24px"
          sx={{ backgroundColor: Theme.palette.white }}
        >
          <Typography
            variant="title"
            fontWeight={700}
            textTransform="uppercase"
          >
            You’re all set.
          </Typography>
          <Typography variant="regular" fontWeight={300} color="#72777A">
            Explore restaurants and more.
          </Typography>
          <ChirzBtn onClick={closeHandler} sx={{ mt: "16px", width: 212 }}>
            Get Started
          </ChirzBtn>
        </Box>
      </Box>
    </ChirzModal>
  );
};

export default WelcomeModal;
