import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Container, IconButton, Rating, Snackbar, Typography } from "@mui/material";

import AddComment from "../../assets/images/AddComment.svg";
import ChirzModal from "./ChirzModal";
import { Theme } from "./Theme";
import { ChirzBtn, LightBtn } from "./ChirzButtons";
import { ChirzInput } from "./ChirzInput";
import { Link } from "react-router-dom";
import store from "../../redux/store";

const ChirzComment = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.users.user);
  const [modal, setModal] = useState(false);
  // State for showing or hiding alert.
  const [alert, setAlert] = useState(false);
  // State for error alert details.
  const [alertDetails, setAlertDetails] = useState({
    color: "error",
    message: "",
  });
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(5);
  const [comments, setComments] = useState(props.data);
  // Create function for getting rating comments
  const ratingHandler = async () => {
    // Turn loggedin user informations to FormatDate.
    let currentLoggedUser = new FormData();
    currentLoggedUser.append("item_id", props.id);
    // Call fetchComments function from rating modal.
    const commentsList = await dispatch.rating.fetchComments(currentLoggedUser);
    let newComments = store.getState().rating.comments;
    setComments(newComments);
    return commentsList;
  };
  // Create function for adding rating comments
  const addRatingHandler = async () => {
    let currentComment = new FormData();
    currentComment.append("user_id", loggedInUser.u_id);
    currentComment.append("item_id", props.id);
    currentComment.append("comment", comment);
    currentComment.append("rate", rate);
    // Call fetchAddComment function from rating modal.
    const addComment = await dispatch.rating.fetchAddComment(currentComment);
    let newCommentError = store.getState().rating.error;
    if (newCommentError !== null) {
      setAlert(true);
      setAlertDetails({
        color: "error",
        message: newCommentError,
      });
    }
    return addComment;
  };
  // Create function for adding a comment
  const addCommentHandler = () => {
    addRatingHandler();
    ratingHandler();
    closeHandler();
  };
  const commentsHandler = () => {
    setModal(true);
  };
  const closeHandler = () => {
    setModal(false);
  };
  // Create show or hide alert function
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  let commentItems;
  if (comments.length !== 0) {
    commentItems = (
      <Box display="flex" flexDirection="column" gap="12px">
        {comments.map((comment) => (
          <Box
            width="100%"
            minHeight={84}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#fff"
            padding="16px"
            borderRadius="5px"
            key={comment.r_id}
          >
            <Typography variant="small" mb="8px">
              “{comment.comment}”
            </Typography>
            <Typography variant="small" fontWeight={500} mb="4px">
              {comment.username}
            </Typography>
            <Rating
              name="read-only"
              size="small"
              value={parseInt(comment.rate)}
              readOnly
            />
          </Box>
        ))}
      </Box>
    );
  } else {
    commentItems = (
      <Box
        width="100%"
        height={84}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>No comments currently available</Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb="8px"
        >
          <Typography variant="regular" fontWeight={700}>
            Comments
          </Typography>
          <IconButton onClick={commentsHandler} aria-label="add comment">
            <img src={AddComment} width={16} height={16} alt="previous" />
          </IconButton>
        </Box>
        {commentItems}
      </Container>
      <ChirzModal open={modal} onClose={closeHandler} innerWidth="100vw">
        <Container padding="20px">
          {loggedInUser && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
              alignItems="center"
              padding="16px"
              borderRadius="16px"
              sx={{ backgroundColor: Theme.palette.white }}
            >
              <Typography
                variant="foodTitle"
                textAlign="center"
                textTransform="uppercase"
              >
                Share your thoughts
              </Typography>
              <Typography variant="regular" textAlign="center" width="75%">
                Your comment will be visible within a few hours.
              </Typography>
              <Rating
                defaultValue={rate}
                onChange={(e) => setRate(e.target.value)}
              />
              <ChirzInput
                label="Comment"
                variant="outlined"
                type="textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{ autoComplete: "off" }}
                sx={{ width: "100%" }}
              />
              <ChirzBtn
                sx={{
                  width: "100%",
                  height: "48px",
                  mt: "16px",
                  textTransform: "uppercase",
                }}
                variant="chirz"
                onClick={addCommentHandler}
              >
                Add comment
              </ChirzBtn>
              <LightBtn
                onClick={closeHandler}
                sx={{
                  width: "100%",
                  height: "48px",
                  textTransform: "uppercase",
                }}
              >
                Cancel
              </LightBtn>
            </Box>
          )}
          {!loggedInUser && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
              alignItems="center"
              padding="16px"
              borderRadius="16px"
              sx={{ backgroundColor: Theme.palette.white }}
            >
              <Typography
                variant="foodTitle"
                textAlign="center"
                textTransform="uppercase"
              >
                Login or register
              </Typography>
              <Typography variant="regular" textAlign="center" width="90%">
                You will be able to save your bookmark wines and leave comments.
              </Typography>
              <ChirzBtn
                LinkComponent={Link}
                to="/login"
                sx={{
                  width: "100%",
                  height: "48px",
                  mt: "16px",
                  textTransform: "uppercase",
                }}
                variant="chirz"
              >
                Log in
              </ChirzBtn>
              <LightBtn
                LinkComponent={Link}
                to="/register"
                sx={{
                  width: "100%",
                  height: "48px",
                  textTransform: "uppercase",
                }}
              >
                Register
              </LightBtn>
            </Box>
          )}
        </Container>
      </ChirzModal>
      <Snackbar
        open={alert}
        autoHideDuration={1500}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertHandler}
          severity={alertDetails.color}
          sx={{ width: "100%" }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChirzComment;
