import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";

import { Theme } from "../../components/UI/Theme";
import { Box, Typography } from "@mui/material";
import OutOfStock from "./DrinkOutOfStock";

import noDrinkCoverWine from "../../assets/images/wine-bottle.png";
import noDrinkCoverCocktails from "../../assets/images/cocktail.png";
import Cold_Brew from "../../assets/images/cold_brew_image.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const RestaurantDrinkItem = (props) => {
  const navigate = useNavigate();
  // Drink Price
  let DrinkItemPrice;
  if (props.category === "Wines") {
    DrinkItemPrice = props.pricePerBottle;
  } else if (props.category === "Cocktails") {
    DrinkItemPrice = props.pricePerGlass;
  } else {
    DrinkItemPrice = props.pricePerGlass;
  }

  // Drink Cover Image
  let drinkCover;
  if (props.img) {
    drinkCover =
      props.category === "Wines" ? noDrinkCoverWine : props.category === "Cocktails" ? noDrinkCoverCocktails : Cold_Brew;
  }
  // Drink Name
  let name;
  if (props.name) {
    if (props.name.length > 32) {
      name = props.name.slice(0, 32) + "...";
    } else {
      name = props.name;
    }
  }
  // Drink Description
  let desc;
  if (props.desc) {
    if (props.desc.length > 20) {
      desc = props.desc.slice(0, 20) + "...";
    } else {
      desc = props.desc;
    }
  }

  // Showing drink details.
  const clickHandler = async () => {
    navigate(`/drink/${props.id}`, {
      state: {
        isColdBrew: props.category === "Cold Brew" ? true : false,
      },
    });
  };

  const isEmSherif = props.isEmSherif;

  return (
    <>
      <Box
        onClick={clickHandler}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
        sx={{
          cursor: "pointer", px: {
            xs: '0px',
            sm: isEmSherif ? '40px' : '0px'
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="16px"
        >
          {
            !isEmSherif &&
            <img
              src={drinkCover}
              width={40}
              height={64}
              style={{
                borderRadius: "5px",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
          }
          <Box display="flex" flexDirection="column" gap="8px">
            {props.name && (
              <Typography width={"100%"} variant="regularTight" fontWeight={700} fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined} textTransform={isEmSherif ? "uppercase" : undefined}>
          
                {isEmSherif ? props.name : name }
              </Typography>
            )}
            {props.desc && (
              <Typography
                variant="smallTight"
                fontFamily={isEmSherif ? "Montserrat" : undefined}
                color={Theme.palette.darkGray}
              >
                {isEmSherif ? props.desc : desc }
              </Typography>
            )}
          </Box>
        </Box>

        <Typography
          position="relative"
          width={90}
          variant="regular"
          fontWeight={700}
          lineHeight="56px"
          textAlign="right"
        >
          {!props.stock && <OutOfStock />}
          {DrinkItemPrice}
        </Typography>
        <Typography mt={"2px"} >
          <MdOutlineArrowForwardIos size={15} color="#354b77"/>
        </Typography>
      </Box>
    </>
  );
};

export default RestaurantDrinkItem;
