import React from "react";

import { Theme } from "../../../components/UI/Theme";
import { Box, Typography } from "@mui/material";
import { ChirzBtn } from "../../../components/UI/ChirzButtons";

import Glass from "../../../assets/images/Glass.svg";
import Bottle from "../../../assets/images/Bottle.svg";
import { Link } from "react-router-dom";

const MatchingWine = (props) => {
  const isEmSherif = props.isEmSherif;
  return (
    <Box key={props.key}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="20px"
        p="14px"
        borderRadius="16px"
        backgroundColor={Theme.palette.white}
        sx={{ filter: "drop-shadow(rgba(0,0,0,0.1) 0px 5px 5px)" }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          {props.name && (
            <Typography textAlign="center" variant="regular" fontWeight={700} fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined} textTransform={isEmSherif ? "uppercase" : undefined}>
              {props.name}
            </Typography>
          )}
          {props.shortDesc && (
            <Typography variant="regular" fontStyle="italic" textAlign="center" fontFamily={isEmSherif ? "Montserrat" : undefined}>
              {props.shortDesc}
            </Typography>
          )}
          {props.year && (
            <Typography textAlign="center" variant="regular" fontFamily={isEmSherif ? "Montserrat" : undefined}>
              {props.year}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-end"
          gap="24px"
        >
          {props.pricePerGlass && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
            >
              <img src={Glass} className="object-contain" height={32} />
              <Typography variant="regularNone" fontWeight={700} fontFamily={isEmSherif ? "Montserrat" : undefined}>
                {props.pricePerGlass}
              </Typography>
              <Typography variant="regularNone" fontFamily={isEmSherif ? "Montserrat" : undefined}>per glass</Typography>
            </Box>
          )}
          {props.pricePerBottle && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
            >
              <img src={Bottle} className="object-contain" height={52} />
              <Typography variant="regularNone" fontWeight={700} fontFamily={isEmSherif ? "Montserrat" : undefined}>
                {props.pricePerBottle}
              </Typography>
              <Typography variant="regularNone" fontFamily={isEmSherif ? "Montserrat" : undefined}>per bottle</Typography>
            </Box>
          )}
        </Box>
        <ChirzBtn
          LinkComponent={Link}
          to={`/drink/${props.id}`}
          sx={{ width: 212, mt: "8px", textTransform: "uppercase" }}
          isEmSherif={isEmSherif}
        >
          See More
        </ChirzBtn>
      </Box>
    </Box>
  );
};

export default MatchingWine;
