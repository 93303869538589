import React from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";

const ChirzModal = (props) => {
  const isFoodModal = props.isFoodModal || false;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: isMobile && isFoodModal ? "baseline" : "center",
      }}
    >
      <Box
        width={props.innerWidth}
        maxWidth={600}
        border="none"
        sx={{ outline: "none" }}
      >
        {props.children}
      </Box>
    </Modal>
  );
};

export default ChirzModal;
