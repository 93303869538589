import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/home/Home";
import Favourites from "./pages/favourites/Favourites";
import Settings from "./pages/settings/Settings";
import Login from "./components/Authentication/login/Login";
import Register from "./components/Authentication/register/Register";
import Chirz404 from "./components/UI/Chirz404";
import RestaurantMenu from "./layouts/restaurantMenu/RestaurantMenu";
import ForgotPassword from "./components/Authentication/login/ForgotPassword";
import Drink from "./pages/drink/Drink";
// import Food from "./pages/food/Food";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/favourites" element={<Favourites />} />
      <Route path="/restaurant/:name/:id" element={<RestaurantMenu />} />
      {/* <Route path="/food/:id" element={<Food />} /> */}
      <Route path="/drink/:id" element={<Drink />} />
      <Route path="*" element={<Chirz404 />} />
    </Routes>
  );
}

export default App;
