import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../../redux/store";
import { useNavigate } from "react-router-dom";

import { Container, Typography, Box, Alert, Snackbar } from "@mui/material";
import DashboardNav from "../../../layouts/dashboard/DashboardNav";
import { ChirzInput } from "../../UI/ChirzInput";
import { ChirzBtn } from "../../UI/ChirzButtons";
import { ChirzLink } from "../../UI/ChirzLink";

import styles from "./Login.module.css";

const Login = () => {
  // State for showing or hiding alert.
  const [alert, setAlert] = useState(false);
  // State for error alert details.
  const [alertDetails, setAlertDetails] = useState({
    color: "error",
    message: "",
  });
  // State for set user email.
  const [email, setEmail] = useState("");
  // State for set user password.
  const [password, setPassword] = useState("");
  // Define Basics.
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Handle Login Function
  const loginHandler = async () => {
    // Turn current user informations to FormatDate.
    let currentUser = new FormData();
    currentUser.append("email", email);
    currentUser.append("password", password);
    // Call fetchLogin function from users modal.
    await dispatch.users.fetchLogin(currentUser);
    // Extract current user informations after fetchLogin.
    let loggedInUser = store.getState().users.user;
    let loggedInError = store.getState().users.loginError;
    // Show alert.
    setAlert(true);
    // Login or show errors
    if (loggedInUser !== undefined) {
      // Turn loggedin user informations to FormatDate.
      let currentLoggedUser = new FormData();
      currentLoggedUser.append("user_id", loggedInUser.u_id);
      // Call fetchProfile function from users modal.
      dispatch.users.fetchProfile(currentLoggedUser);
      // Set welcome alert details
      setAlertDetails({
        color: "success",
        message: "Welcome " + loggedInUser.username,
      });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      // Set error alert details
      setAlertDetails({
        color: "error",
        message: loggedInError,
      });
    }
  };
  //Handle Forgot Password Function
  const forgotPasswordHandler = async () => {
    await dispatch.users.fetchForgotPassword();
  };
  // Create show or hide alert function
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  return (
    <>
      <DashboardNav title="Log in" />
      <Container
        sx={{ display: "flex", justifyContent: "center", minHeight: "80vh" }}
      >
        <form className={styles.login}>
          <Box display="flex" flexDirection="column" gap="16px">
            <ChirzInput
              label="email@example.com"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ autoComplete: "off" }}
            />
            <ChirzInput
              label="password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ChirzLink
              href="/forgot-password"
              variant="chirz"
              underline="hover"
              onClick={forgotPasswordHandler}
            >
              Forgot password?
            </ChirzLink>
            <ChirzLink
              href="/register"
              variant="chirz"
              underline="hover"
              fontSize={18}
              textAlign="center"
              mt="24px"
            >
              Don't have an account?
            </ChirzLink>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            <Typography width="100%" variant="small" lineHeight="23px">
              By continuing, you agree to our
              <ChirzLink
                href="/terms-service"
                variant="chirz"
                underline="hover"
              >
                Terms of Service
              </ChirzLink>
              and
              <ChirzLink
                href="https://www.chirz.co.uk/privacy"
                variant="chirz"
                underline="hover"
              >
                Privacy Policy
              </ChirzLink>
              .
            </Typography>
            <ChirzBtn
              sx={{ textTransform: "uppercase", width: "100%" }}
              onClick={loginHandler}
            >
              Log in
            </ChirzBtn>
          </Box>
        </form>
      </Container>
      <Snackbar
        open={alert}
        autoHideDuration={1200}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertHandler}
          severity={alertDetails.color}
          sx={{ width: "100%" }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
