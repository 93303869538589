import Api from "../../lib/api";

export const users = {
  state: {
    user: undefined,
    userLocation: undefined,
    loginError: undefined,
    registerError: undefined,
    forgotPasswordError: undefined,
    forgotPasswordAlert: undefined,
    profileDetails: {},
  },
  reducers: {
    setUserLocation(state, payload){
      return {
        ...state,
        userLocation: payload,
      };
    },
    setLoggedIn(state, payload) {
      return {
        ...state,
        user: payload,
      };
    },
    setLoggedOut(state) {
      return {
        ...state,
        user: null,
      };
    },
    setLoginError(state, payload) {
      return {
        ...state,
        loginError: payload,
      };
    },
    setRegisterError(state, payload) {
      return {
        ...state,
        registerError: payload,
      };
    },
    setForgotPasswordError(state, payload) {
      return {
        ...state,
        forgotPasswordError: payload,
      };
    },
    setForgotPasswordAlert(state, payload) {
      return {
        ...state,
        forgotPasswordAlert: payload,
      };
    },
    setProfile(state, payload) {
      return {
        ...state,
        profileDetails: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchUserLocation(payload) {
      try {
        await dispatch.users.setUserLocation(payload);
      } catch (error) {}
    },
    async fetchRegister(payload) {
      try {
        const data = Api.post("/?action=standard_registration", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            await dispatch.users.setProfile({});
            if (res.status === 0) {
              await dispatch.users.setRegisterError(res.message);
            } else {
              await dispatch.users.setRegisterError(undefined);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchLogin(payload) {
      try {
        const data = Api.post("/?action=login", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            if (res.status === 0) {
              await dispatch.users.setLoginError(res.message);
              await dispatch.users.setProfile({});
            } else {
              await dispatch.users.setLoggedIn(res.data);
              await dispatch.users.setProfile(res.data);
              await dispatch.users.setLoginError(undefined);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchLogout() {
      try {
        await dispatch.users.setProfile({});
        await dispatch.users.setLoggedOut();
        await dispatch.users.setLoginError(undefined);
        await dispatch.users.setRegisterError(undefined);
      } catch (error) {}
    },
    async fetchProfile(payload) {
      try {
        const data = Api.post("/?action=view_profile", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            if (res.status !== 0) {
              await dispatch.users.setProfile(res.data);
            } else {
              await dispatch.users.setProfile({});
            }
          })
          .catch();
        return data;
      } catch (error) {}
    },
    async fetchEditProfile(payload) {
      try {
        const data = Api.post("/?action=edit_profile", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            if (res.status !== 0) {
              await dispatch.users.setLoggedIn(res.data);
              await dispatch.users.setProfile(res.data);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchForgotPassword(payload) {
      try {
        const data = Api.post("/?action=forget_password", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            if (res.status === 0) {
              await dispatch.users.setForgotPasswordAlert(undefined);
              await dispatch.users.setForgotPasswordError(res.message);
            } else {
              await dispatch.users.setForgotPasswordError(undefined);
              await dispatch.users.setForgotPasswordAlert(res.message);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchDeleteAccount(payload) {
      try {
        const data = Api.post("/?action=delete_acount", payload)
          .then(async function (response) {
            const res = await mapper(response.data);
            if (res.status !== 0) {
              await dispatch.users.setProfile({});
              await dispatch.users.setLoggedOut();
              await dispatch.users.setLoginError(undefined);
              await dispatch.users.setRegisterError(undefined);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};

const mapper = async (data) => {
  const responseArray = data.split("\n");
  const res = await JSON.parse(responseArray[1]);
  return res;
};
