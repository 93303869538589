import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChirzInput } from "../../../../components/UI/ChirzInput";

const PersonalInfo = (props) => {
  const [info, setInfo] = useState(props.details);
  const infoRef = useRef({});

  const changeHandler = (e) => {
    infoRef.current = { [e.target.name]: e.target.value };
    setInfo((prevInfo) => ({ ...prevInfo, ...infoRef.current }));
    props.onChange(infoRef.current);
  };

  useEffect(() => {
    if (info.name !== "" && info.email !== "") {
      props.onConfirm(false);
    } else{
      props.onConfirm(true);
    }
  }, [info]);

  return (
    <Box display="flex" flexDirection="column" gap="16px" pt="32px">
      <Typography variant="largeNone" fontWeight={700} pb="8px">
        A little bit about you.
      </Typography>
      <ChirzInput
        name="name"
        label="Name"
        value={info.name}
        variant="outlined"
        type="text"
        onChange={changeHandler}
      />
      <ChirzInput
        name="email"
        label="Email"
        value={info.email}
        variant="outlined"
        type="email"
        onChange={changeHandler}
      />
    </Box>
  );
};

export default PersonalInfo;
