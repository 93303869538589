import React from "react";
import { useState } from "react";

import { Theme } from "../../components/UI/Theme";
import { Box, Typography } from "@mui/material";
import Food from "../modal/FoodModal";
import noFoodCover from "../../assets/images/NoImg.png";
import noFoodDetailsCover from "../../assets/images/fooddetailscover.png";
import { useDispatch } from "react-redux";
import store from "../../redux/store";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const RestaurantFoodItem = (props) => {
  // Define Basics.
  const dispatch = useDispatch();
  const matchingWines = store.getState().food.matchingWines;
  const matchingCocktails = store.getState().food.matchingCocktails;
  const matchingColdBrew = store.getState().food.matchingColdBrew;
  // Food Cover Image
  let foodCover;
  let foodDetailsCover;
  if (props.img) {
    if (props.img.substring(props.img.lastIndexOf("/") + 1) !== "") {
      foodCover = props.img.replace('http://', 'https://');
      foodDetailsCover = props.img.replace('http://', 'https://');
    } else {
      foodCover = noFoodCover;
      foodDetailsCover = noFoodDetailsCover;
    }
  }
  // Food Name
  let name;
  if (props.name) {
    if (props.name.length > 40) {
      name = props.name.slice(0, 40) + "...";
    } else {
      name = props.name;
    }
  }
  // Food Description
  let desc;
  if (props.desc) {
    if (props.desc.length > 36) {
      desc = props.desc.slice(0, 36) + "...";
    } else {
      desc = props.desc;
    }
  }
  // State for showing or hiding food details.
  const [foodDetails, setFoodDetails] = useState(false);

  // Showing food details.
  const clickHandler = async () => {
    await dispatch.food.fetchMatchingWines({
      food_id: props.id,
    });
    await dispatch.food.fetchMatchingCocktails({
      wine_by: "cocktail",
      food_id: props.id,
    });
    await dispatch.food.fetchMatchingColdBrew({
      food_id: props.id,
      wine_by: "cold_brew"
    });
    setFoodDetails(true);
  };
  // Closing food details based on situation from FoodModal
  const closeHandler = (situation) => {
    setFoodDetails(situation);
  };

  const isEmSherif = props.isEmSherif;

  return (
    <>
      <Box
        onClick={clickHandler}
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="16px"
        sx={{
          cursor: "pointer", px: {
            xs: '0px',
            sm: isEmSherif ? '40px' : '0px'
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="16px"
        >
          {
            !isEmSherif &&
            <img
              src={foodCover}
              width={64}
              height={64}
              style={{
                borderRadius: "5px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              onError={(e) => {
                e.target.src = noFoodCover;
              }}
              alt="food cover"
            />
          }

          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            {props.name && (
              <Typography width={"100%"} variant="regularTight" fontWeight={700} fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined} textTransform={isEmSherif ? "uppercase" : undefined}>
                {isEmSherif ? props.name : name}
              </Typography>
            )}
            {props.desc && (
              <Typography
                width={"100%"}
                height={32}
                variant="smallTight"
                color={Theme.palette.darkGray}
                fontFamily={isEmSherif ? "Montserrat" : undefined}
              >
                {isEmSherif ? props.desc : desc}
              </Typography>
            )}
          </Box>
        </Box>
        {props.price && (
          <Typography variant="regular" fontWeight={700} lineHeight="56px" fontFamily={isEmSherif ? "Montserrat" : undefined}>
            {props.price}
          </Typography>
        )}
        <Typography mt={"2px"} >
          <MdOutlineArrowForwardIos size={15} color="#354b77"/>
        </Typography>
      </Box>
      <Food
        open={foodDetails}
        close={closeHandler}
        name={props.name}
        desc={props.desc}
        img={foodDetailsCover}
        price={props.price}
        matchingWines={matchingWines}
        matchingCocktails={matchingCocktails}
        matchingColdBrew={matchingColdBrew}
        isEmSherif={isEmSherif}
      />
    </>
  );
};

export default RestaurantFoodItem;
