import createLoadingPlugin from "@rematch/loading";

export const loadingPlugin = createLoadingPlugin({
  whitelist: [
    "users",
    "restaurants",
    "food",
    "drink",
    "favourite",
    "rating",
    "location",
  ],
  type: "boolean",
});
