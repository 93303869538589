import React from "react";

import { Theme } from "../../../components/UI/Theme";
import { Box, Typography } from "@mui/material";
import { ChirzBtn } from "../../../components/UI/ChirzButtons";

import { useNavigate } from "react-router-dom";
import { GiIceCubes } from "react-icons/gi";

const MatchingColdBrew = (props) => {
  const isEmSherif = props.isEmSherif;
  const navigate = useNavigate();

  return (
    <Box key={props.key}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="24px"
      p="32px"
      borderRadius="16px"
      backgroundColor={Theme.palette.white}
      sx={{ filter: "drop-shadow(rgba(0,0,0,0.1) 0px 5px 5px)" }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {props.name && (
          <Typography variant="regular" fontWeight={700} fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined} textTransform={isEmSherif ? "uppercase" : undefined}>
            {props.name}
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="24px"
      >
        {props.pricePerGlass && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="10px"
          >
            <GiIceCubes size={36} />
            <Typography variant="regularNone" fontWeight={700} fontFamily={isEmSherif ? "Montserrat" : undefined}>
              {props.pricePerGlass}
            </Typography>
          </Box>
        )}
      </Box>
      <ChirzBtn
        sx={{ width: 212, mt: "8px", textTransform: "uppercase" }}
        isEmSherif={isEmSherif}
        onClick={() => navigate(`/drink/${props.id}`, {
          state : { isColdBrew : true }
        })}
      >
        See More
      </ChirzBtn>
    </Box>
  </Box>
  );
};

export default MatchingColdBrew;
