import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ChirzSlider.css";

const ChirzSlider = (props) => {
  const sliderStyle = {
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "45px",
    swipeToSlide: true,
    speed: 500,
  };
  return <Slider {...sliderStyle} className="chirz-slider">{props.children}</Slider>;
};

export default ChirzSlider;
