import React from "react";

const Chirz404 = () => {
  return (
    <>
      <h1>404 Page</h1>
    </>
  );
};

export default Chirz404;
