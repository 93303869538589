import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Theme } from "./Theme";

const getButtonStyles = (props) => ({
    background: props.isEmSherif ? "#354b77" : "linear-gradient(to right top,#090A0A, #990012)",
    minWidth: 112,
    maxWidth: 327,
    borderRadius: 48,
    height: 40,
    font: Theme.typography.smallTight,
    fontWeight: 300,
    color: props.isEmSherif ? "#ffffff" :"#FFF8F5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      background: props.isEmSherif ? "#97bbe3" : Theme.palette.sky,
      color: props.isEmSherif ? "#183861" : Theme.palette.chirz.red,
    },
    "&:disabled": {
      background: Theme.palette.sky,
      color: Theme.palette.lightGray,
      cursor: "not-allowed",
    },
});

export const ChirzBtn = styled(Button)((props) => getButtonStyles(props));

export const LightBtn = styled(ChirzBtn)({
  background: "#fff",
  color: Theme.palette.chirz.red,
  "&:hover": {
    background: "linear-gradient(to right top,#090A0A, #990012)",
    color: "#fff",
  },
});
