import React from "react";
import { Box, Divider, Modal, Typography } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import ChirzModal from "../../components/UI/ChirzModal";
import { Theme } from "../../components/UI/Theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InfoModal = ({
  infoModal,
  setInfoModal,
  title,
  description,
  isEmSherif,
}) => {
  return (
    <>
      <ChirzModal open={infoModal} onClose={() => setInfoModal(false)}>
        <Box
          p="20px"
          sx={{
            borderRadius: "16px",
            overflow: "hidden",
            backgroundColor: Theme.palette.offWhite,
            width : {sm : "100%", xs : "350px"},
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="12px"
          >
            <Typography
              variant="title"
              color={isEmSherif ? "#183861" : ""}
              fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined}
              textTransform={isEmSherif ? "uppercase" : undefined}
            >
              {title}
            </Typography>
            <IoMdClose
              size={24}
              onClick={() => setInfoModal(false)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Divider sx={{ my: "12px" }} />
          <Typography
            variant="subtitle1"
            color={isEmSherif ? "#183861" : ""}
            fontFamily={isEmSherif ? "Montserrat" : undefined}
          >
            {description}
          </Typography>
        </Box>
      </ChirzModal>
    </>
  );
};

export default InfoModal;
