import { createTheme } from "@mui/material";

export const Theme = createTheme({
  palette: {
    primary: {
      light: "#E3E5E5",
      main: "#990012",
      dark: "#5D010C",
      contrastText: "#090A0A",
    },
    chirz: {
      red: "#990012",
      dark: "#5D010C",
      light: "#9990FF",
      lighter: "#C6C4FF",
      main: "#6B4EFF",
    },
    white: "#ffffff",
    offWhite: "#F9F9F9",
    lightGray: "#979C9E",
    darkGray: "#525252",
    almostBlack: "#090A0A",
    sky: "#E3E5E5",
  },
  typography: {
    fontFamily: "Lato",
    color: "#090A0A",
    logo: {
      fontFamily: "Urbanist",
      fontSize: "90px",
      fontWeight: "600",
    },
    foodTitle: {
      fontFamily: "Urbanist",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "700",
    },
    title: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "24px",
      lineHeight: "32px",
    },
    titleLarge: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "48px",
      lineHeight: "56px",
    },
    titleSmall: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "24px",
      lineHeight: "32px",
    },
    large: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "18px",
      lineHeight: "24px",
    },
    largeNone: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "18px",
      lineHeight: "18px",
    },
    largeTight: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "18px",
      lineHeight: "20px",
    },
    regular: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "16px",
      lineHeight: "24px",
    },
    regularNone: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "16px",
      lineHeight: "16px",
    },
    regularTight: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "16px",
      lineHeight: "20px",
    },
    small: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "14px",
      lineHeight: "20px",
    },
    smallNone: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "14px",
      lineHeight: "14px",
    },
    smallTight: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "14px",
      lineHeight: "16px",
    },
    tiny: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "12px",
      lineHeight: "16px",
    },
    tinyNone: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "12px",
      lineHeight: "12px",
    },
    tinyTight: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "12px",
      lineHeight: "14px",
    },
    superTiny: {
      color: "#090A0A",
      fontFamily: "Lato",
      fontSize: "10px",
      lineHeight: "16px",
    },
    tinyBadge: {
      color: "#990012",
      fontFamily: "Inter",
      fontSize: "10px",
      lineHeight: "18px",
      textTransform: "uppercase",
      textDecoration: "underline",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "100vw",
          "@media (min-width:600px)": {
            width: "30%",
            minWidth: 360,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 48,
          height: 48,
        },
      },
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "scrollable" },
          style: {
            minHeight: 32,
            ".MuiTabs-flexContainer": {
              gap: "10px",
            },
            ".MuiTab-root": {
              minWidth: 70,
              minHeight: 32,
              height: 32,
              padding: "8px 16px",
              backgroundColor: "#F2F4F5",
              borderRadius: "32px",
              color: "#090A0A",
              fontFamily: "Lato",
              fontSize: "16px",
              lineHeight: "16px",
              textTransform: "none",
              "&.Mui-selected": {
                color: "#5D010C",
                backgroundColor: "#FFD7DB",
              },
            },
          },
        },
      ],
    },
    MuiTab: {
      variants: [
        {
          props: { variant: "chirz" },
          style: {
            justifyContent: "flex-start",
            gap: "6px",
            fontSize: 16,
            textTransform: "none",
            minHeight: 56,
            color: "#090A0A",
            "&:hover": {
              color: "#990012",
            },
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 56,
          minHeight: 56,
          "@media (min-width:600px)": {
            height: 64,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          border: "none",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
        },
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "chirz" },
          style: {
            textDecoration: "none",
            fontFamily: "Lato",
            outline: "none",
          },
        },
      ],
    },
    MuiAutocomplete: {
      variants: [
        {
          props: {
            variant: "chirz",
          },
          style: {
            width: "100%",
            fieldset: {
              border: "none",
            },
            "& .MuiAutocomplete-popupIndicator": {
              display: "none !important",
            },
          },
        },
      ],
    },
    MuiSlider: {
      variants: [
        {
          props: {
            variant: "sweetness",
          },
          style: {
            marginTop: "-10px",
            "& .MuiSlider-rail": {
              background:
                "linear-gradient(to right,  #72C1C6 0px 5px, #AFD29D 5px 24px, #CCDD8F 24px 50px, #F4EC96 50px 172px,#F3CA78 172px 314px)",
              opacity: 1,
            },
            "& .MuiSlider-track": {
              background:
                "linear-gradient(to right,  #72C1C6 0px 5px, #AFD29D 5px 24px, #CCDD8F 24px 50px, #F4EC96 50px 172px,#F3CA78 172px 314px)",
            },
            "& .MuiSlider-track": {
              border: "none",
              background: "transparent",
            },
            "& .MuiSlider-thumb ": {
              width: 3,
              height: 22,
              borderRadius: "2px",
              "&:before": {
                boxShadow: "none",
              },
            },

            "& .MuiSlider-markLabel": {
              fontSize: "16px",
              lineHeight: "24px",
              color: "#090A0A",
            },
            "&.Mui-disabled": {
              color: "#990012",
            },
          },
        },
        {
          props: {
            variant: "acidity",
          },
          style: {
            marginTop: "-10px",
            "& .MuiSlider-rail": {
              background:
                "linear-gradient(to right,  #FDF080 0px 84px, #DDD071 84px 108px, #BB4968 100px 126px, #D85175 126px 314px)",
              opacity: 1,
            },
            "& .MuiSlider-track": {
              background:
                "linear-gradient(to right,  #FDF080 0px 84px, #DDD071 84px 108px, #BB4968 100px 126px, #D85175 126px 314px)",
            },
            "& .MuiSlider-track": {
              border: "none",
              background: "transparent",
            },
            "& .MuiSlider-thumb ": {
              width: 3,
              height: 22,
              borderRadius: "2px",
              "&:before": {
                boxShadow: "none",
              },
            },

            "& .MuiSlider-markLabel": {
              fontSize: "16px",
              lineHeight: "24px",
              color: "#090A0A",
            },
            "&.Mui-disabled": {
              color: "#990012",
            },
          },
        },
      ],
    },
  },
});
