import Api from "../../lib/api";

export const location = {
  state: {
    location: [],
  },
  reducers: {
    setLocation(state, payload) {
      return {
        ...state,
        location: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchLocation(payload) {
      try {
        const data = Api.post("/?action=get_location", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.location.setLocation(res.data);
            } else {
              await dispatch.location.setLocation([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};

const mapper = async (data) => {
  const responseArray = data.split("\n");
  const res = await JSON.parse(responseArray[1]);
  return res;
};
