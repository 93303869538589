import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Theme } from "../../components/UI/Theme";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Typography,
  Tabs,
  Tab,
  Alert,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  Menu,
  Badge,
} from "@mui/material";
import { ChirzLink } from "../../components/UI/ChirzLink";
import Search from "../../components/Search/Search";
import RestaurantItems from "./RestaurantItems";

import "./RestaurantMenu.css";
import BackIcon from "../../assets/images/BackIcon.svg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import store from "../../redux/store";
import ChirzLoader from "../../components/UI/ChirzLoader";
import Filters from "./Filters";
import { IoFilter } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";
import InfoModal from "./InfoModal";

const EM_SHERIF_ID = 428;

const RestaurantMenu = () => {
  // Define Basics.
  let {name, id} = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  //Extract restaurant name by using name in Url.
  const nearRestaurants = useSelector(
    (state) => state.restaurants.nearRestaurants
  );

  const singleData = useSelector(
    (state) => state.restaurants.singleRestaurants
  );
  const suggestedRestaurants = useSelector(
    (state) => state.restaurants.suggestedRestaurants
  );
  const restaurants = nearRestaurants.concat(suggestedRestaurants);
  const restaurantArr = restaurants.find(
    (restaurant) =>
      restaurant.Restaurantname.replace(/\s/g, "").toLocaleLowerCase() === name
  );
  const [menuType, setMenuType] = useState("");
  const [allFoodDetails, setAllFoodDetails] = useState([]);
  const [allFoodName, setAllFoodName] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const [searchResult, setSearchResult] = useState(undefined);
  const [filterValue, setFilterValue] = useState({
    type: null,
    region: null,
    grape: null,
    alcohol: null,
    year: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [infoModal, setInfoModal] = useState(false);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const changeHandler = (event, value) => {
    setMenuType(value);
    setFilterValue({
      type : null,
      region : null,
      grape : null,
      alcohol : null,
      year : null
    })
  };
  const fetchData = async () => {
    await dispatch.restaurants.fetchSingleRestaurant(id);

    await dispatch.food.fetchFoodList({
      restaurant_id: id,
    });
    let foodListStore = store.getState().food.foodList;
    let foodListType = [
      ...new Set(foodListStore.map((food) => food.menu_name)),
    ];
    let foodListDetails = [...new Set(foodListStore.map((food) => food.food))];
    foodListDetails = [].concat(...foodListDetails);
    let foodListName = [
      ...new Set(
        foodListStore.map((food) => food.food.map((item) => item.food_name))
      ),
    ];
    foodListName = [].concat(...foodListName);
    setAllFoodName(foodListName);
    setFoodList(foodListType);
    setMenuType(foodListType[0]);
    setAllFoodDetails(foodListDetails);
    setLoading(false);
  };
  const searchHandler = (item) => {
    if (item !== null && item !== "") {
      const searchDetails = allFoodDetails.find(
        (food) => food.food_name === item
      );
      if (searchDetails !== undefined) {
        let foodListStore = store.getState().food.foodList;
        let searchType = foodListStore.find((food) =>
          food.food.some((item) => item === searchDetails)
        );
        if (searchType) {
          setSearchResult(searchDetails);
          setMenuType(searchType.menu_name);
        }
      }
    } else {
      setSearchResult(undefined);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const isEmSherif = useMemo(() => {
    return +id === EM_SHERIF_ID;
  }, [id]);

  useEffect(() => {
    const filterCount = Object.values(filterValue).filter((value) => value !== null && value !== "").length;
    setAppliedFilterCount(filterCount);
  }, [filterValue]);

  return (
    <>
      <AppBar sx={{ backgroundColor: Theme.palette.white, position: "sticky", }}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: Theme.palette.white,
          }}
          width="100%"
        >
          <Container
            width="100%"
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <Typography
                variant="title"
                color={isEmSherif ? "#183861" : ""}
                fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined}
                textTransform={isEmSherif ? "uppercase" : undefined}
              >
                {restaurantArr !== undefined
                  ? restaurantArr.Restaurantname
                  : singleData?.Restaurantname}
              </Typography>
              {singleData?.Description && (
                <IoInformationCircleOutline onClick={() => setInfoModal(true)} style={{cursor: "pointer", marginTop: "3px"}}/>
              )}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      {foodList.length === 0 && (
        <Container>
          <Box
            width="100%"
            height="75vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              <ChirzLoader />
            ) : (
              <Alert severity="info">
                No Menu Available at the Moment. Stay Tuned for Updates!
              </Alert>
            )}
          </Box>
        </Container>
      )}
      {foodList.length > 0 && (
        <Container sx={{ my: "10px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={menuType === "Wine" ? 10 : 12}>
             <Stack spacing={1}>
              <Search
                item={allFoodName}
                searchKeyHandler={searchHandler}
                isEmSherif={isEmSherif}
              />
              </Stack>
            </Grid>
            {menuType === "Wine" && (
              <Grid item xs={2}>
                <Stack spacing={1}>
                    <Tooltip title="Filters">
                      <IconButton
                          color="#183861"
                          id="wine-filter"
                          aria-controls={open ? "wine-filter" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          sx={{ width: "45px", height: "45px", marginLeft : "auto !important" }}
                      >
                         <Badge
                            badgeContent={appliedFilterCount}
                            color="success"
                            sx={{ "& .MuiBadge-badge": { top: 2, right: 1 } }}
                        >
                          <IoFilter color="#183861"/>
                        </Badge>
                      </IconButton>
                    </Tooltip>

                    <Menu
                      id="wine-filter"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{ "aria-labelledby": "wine-filter", sx: { p: 1.25, minWidth: 150 } }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Filters setFilterValue={setFilterValue} filterValue={filterValue} isEmSherif={isEmSherif}/>
                    </Menu>
                </Stack>
              </Grid>
            )}
          </Grid>
          <Tabs
            aria-label="restaurant-menu"
            sx={{mt: { xs : "20px", sm : "12px"}, mb: "36px"}}
            value={menuType}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="transparent"
            onChange={changeHandler}
          >
            {foodList.map((type) => (
              <Tab
                label={type}
                key={type.replace(/\s/g, "").toLocaleLowerCase()}
                value={type}
                sx={{
                  color: isEmSherif ? "#183861 !important" : "",
                  backgroundColor: isEmSherif ? "#97bbe3 !important" : "",
                  "&.Mui-selected": {
                    color: isEmSherif ? "#ffffff !important" : "",
                    backgroundColor: isEmSherif ? "#354b77 !important" : "",
                  },
                  fontFamily: isEmSherif ? "Montserrat !important" : undefined,
                }}
              />
            ))}
          </Tabs>
          {foodList.map((type) => (
            <RestaurantItems
              value={menuType}
              name={type}
              key={type.replace(/\s/g, "").toLocaleLowerCase()}
              id={type.replace(/\s/g, "").toLocaleLowerCase()}
              search={searchResult && searchResult}
              isEmSherif={isEmSherif}
              filterValue={filterValue}
            />
          ))}
        </Container>
      )}

      {infoModal && (
        <InfoModal 
          setInfoModal={setInfoModal} 
          infoModal={infoModal} 
          title={
          restaurantArr !== undefined
            ? restaurantArr.Restaurantname
            : singleData?.Restaurantname
          }
          description={singleData?.Description}
          isEmSherif={isEmSherif}
        />
      )}
    </>
  );
};

export default RestaurantMenu;
