import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../../../redux/store";

import { Box, Container, Typography, Alert, Snackbar } from "@mui/material";
import { ChirzBtn } from "../../../components/UI/ChirzButtons";
import { ChirzLinearProgress } from "../../../components/UI/ChirzLinearProgress";
import PersonalInfo from "./formContent/PersonalInfo";
import PhoneInfo from "./formContent/PhoneInfo";
import PasswordInfo from "./formContent/PasswordInfo";
import RegisterNav from "./navigations/RegisterNav";
import { ChirzLink } from "../../../components/UI/ChirzLink";
import WelcomeModal from "../../../layouts/modal/WelcomeModal";

import "./Register.css";

const stepProgressValue = [
  {
    step: 1,
    progress: 33.3,
  },
  {
    step: 2,
    progress: 66.6,
  },
  {
    step: 3,
    progress: 90,
  },
];
const infoDetails = {
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
};
const Register = () => {
  const [alert, setAlert] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    color: "error",
    message: "",
  });
  let registerUser = new FormData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [progressValue, setProgressValue] = useState(
    stepProgressValue[0].progress
  );
  const [info, setInfo] = useState(infoDetails);
  const [modal, setModal] = useState(false);

  const registerHandler = async (info) => {
    await dispatch.users.fetchRegister(info);
    let registeredError = store.getState().users.registerError;
    if (registeredError !== undefined) {
      setAlert(true);
      setAlertDetails({
        color: "error",
        message: registeredError,
      });
    } else {
      submitHandler();
    }
  };
  const modalHandler = (opened) => {
    setModal(opened);
  };
  const submitHandler = () => {
    modalHandler(true);
  };
  const clickHandler = () => {
    if (step === 3) {
      registerUser.append("username", info.name);
      registerUser.append("email", info.email);
      registerUser.append("password", info.password);
      registerUser.append("phone_number", info.phone);
      registerHandler(registerUser);
    } else {
      setProgressValue(stepProgressValue[step].progress);
      setStep((prevStep) => {
        return prevStep + 1;
      });
    }
  };
  const changeHandler = (details) => {
    setInfo((prevInfo) => Object.assign(prevInfo, details));
  };
  const prevHandler = () => {
    if (step > 1) {
      setProgressValue(stepProgressValue[step - 2].progress);
      setStep((prevStep) => {
        return prevStep - 1;
      });
    } else {
      navigate("/");
    }
  };
  const confirmHandler = (disabled) => {
    if (disabled) {
      document.getElementById("confirmBtn" + step).disabled = disabled;
    } else {
      document.getElementById("confirmBtn" + step).removeAttribute("disabled");
    }
  };
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const formContent = (step) => {
    switch (step) {
      case 1:
        return (
          <PersonalInfo
            details={info}
            onChange={changeHandler}
            onConfirm={confirmHandler}
          />
        );
      case 2:
        return (
          <PhoneInfo
            details={info}
            onChange={changeHandler}
            onConfirm={confirmHandler}
          />
        );
      case 3:
        return (
          <PasswordInfo
            onConfirm={confirmHandler}
            details={info}
            onChange={changeHandler}
          />
        );
      default:
        return (
          <PersonalInfo
            details={info}
            onChange={changeHandler}
            onConfirm={confirmHandler}
          />
        );
    }
  };
  const footerContent = (step) => {
    switch (step) {
      case 3:
        return (
          <>
            <Typography variant="small" lineHeight="23px">
              By continuing, you agree to our
              <ChirzLink
                underline="hover"
                href="/terms-service"
                variant="chirz"
              >
                Terms of Service
              </ChirzLink>
              and
              <ChirzLink
                underline="hover"
                href="https://www.chirz.co.uk/privacy"
                variant="chirz"
              >
                Privacy Policy
              </ChirzLink>
              .
            </Typography>
            <ChirzBtn
              id={"confirmBtn" + step}
              sx={{ textTransform: "uppercase", width: "100%" }}
              onClick={clickHandler}
            >
              Complete registration
            </ChirzBtn>
          </>
        );
      default:
        return (
          <>
            <ChirzBtn
              id={"confirmBtn" + step}
              sx={{ textTransform: "uppercase", width: "100%" }}
              onClick={clickHandler}
            >
              Continue
            </ChirzBtn>
          </>
        );
    }
  };
  return (
    <>
      <RegisterNav onClick={prevHandler} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "78vh",
        }}
      >
        <Box width="100%" maxWidth={600} py="8px" px="4px">
          <ChirzLinearProgress variant="determinate" value={progressValue} />
          <form>{formContent(step)}</form>
        </Box>
        <Box
          width="100%"
          maxWidth={600}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
          py="8px"
          px="4px"
        >
          {footerContent(step)}
        </Box>
      </Container>
      <WelcomeModal open={modal} onOpen={modalHandler} />
      <Snackbar
        open={alert}
        autoHideDuration={1200}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertHandler}
          severity={alertDetails.color}
          sx={{ width: "100%" }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Register;
